import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import BDO from 'src/assets/img/bdo-gift.svg';
import BPI from 'src/assets/img/bpi.svg';
import GCash from 'src/assets/img/gcash.svg';
import BCA from 'src/assets/img/bca.svg';
import * as Styles from './styled';

const GiftItem = ({svgName, providerName, accountNum, accountName}: Props) => {
  const [height, setHeight] = useState<string | number>(0);

  const getImage = () => {
    return {
      "bdo": <BDO />,
      "bpi": <BPI />,
      "gcash": <GCash />,
      "bca": <BCA />
    }
  }
  
  return (
    <Styles.GiftItemWrapper>
      <Styles.GiftItemButton onClick={() => setHeight(height === 0 ? "auto" : 0)}>
        {getImage()[svgName]}        
      </Styles.GiftItemButton>

      <AnimateHeight
        duration={ 500 }
        height={ height }
      >
        <Styles.BankDetails>
          <Styles.ProviderName>
            {providerName}
          </Styles.ProviderName>
          <br />
          <span>
            <b>Account Number</b> <br/>
            {accountNum}
          </span>
          <br />
          <span>
            <b>Account Name</b> <br />
            {accountName}
          </span>
        </Styles.BankDetails>
      </AnimateHeight>
    </Styles.GiftItemWrapper>
  )
}

type Props = {
  svgName: "bdo" | "bpi" | "gcash" | "bca";
  providerName: string;
  accountNum: string;
  accountName: string;
}

export default GiftItem;