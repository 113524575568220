import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

import ActionLink from 'src/components/ActionLink';
import Logo from 'src/assets/img/david-and-eunice-logo.svg';
import * as Styles from './styled';

const HomeHeader = ({refs}: Props) => {
  const goTo = (refIndex: string) => {
    const ref = refs.filter((r) => r.name === refIndex);
    ref[0]?.ref?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <Styles.HomeHeaderWrapper>
      <StaticImage 
        className="flower-bg"
        src="../../assets/img/watercolor-flower.jpeg" 
        alt="Flower Background" 
      />
      <Styles.HeaderColumns>
        <Logo className="logo" />

        <Styles.ActionLinks>
          {/* <ActionLink actionName="See details and photos" 
            svgName="rings" 
            generalName="Info" 
            link="/#love-note"  /> */}
          <ActionLink actionName="Send a love note!" 
            svgName="loveLetter" 
            generalName="Wishes" 
            link="/#love-note" 
            onClick={() => goTo("love-note")}  />
          <ActionLink actionName="Send a gift!" 
            svgName="giveLove" 
            generalName="Gifts" 
            link="/#give-gifts" 
            onClick={() => goTo("send-gifts")}  />
        </Styles.ActionLinks>
      </Styles.HeaderColumns>
    </Styles.HomeHeaderWrapper>
  )
}

type Props = {
  refs: any[];
}

export default HomeHeader;