import styled from 'styled-components';

export const SendGiftsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  position: relative;
  padding: 4.6rem 5.6rem;
  height: 100vh;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 1rem;
  }
`

export const GiftsRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-wrap: wrap;
    max-width: 100vw;
  } 
`