import React from 'react';

import Rings from 'src/assets/img/rings.svg';
import LoveLetter from 'src/assets/img/loveletter.svg';
import GiveLove from 'src/assets/img/givelove.svg';
import * as Styles from './styled';

const ActionLink = ({svgName, generalName, actionName, link, onClick}: Props) => {
  const getImage = () => {
    return {
      "rings": <Rings />,
      "loveLetter": <LoveLetter />,
      "giveLove": <GiveLove />
    }
  }
  
  return (
    <Styles.ActionLinkWrapper onClick={onClick}>
      <a href={link}>
        {getImage()[svgName]}

        <Styles.RightSide>
          <h5>{generalName}</h5>
          <h2>{actionName}</h2>
        </Styles.RightSide>
      </a>
    </Styles.ActionLinkWrapper>
  )
}

type Props = {
  svgName: "rings" | "loveLetter" | "giveLove";
  generalName: string; 
  actionName: string;
  link: string;
  onClick?: () => void;
}

export default ActionLink;