import React, { FormEvent, useState } from 'react';
import { toast } from "react-toastify";

import Modal from 'src/components/Modal';
import * as Styles from './styled';

function encode(data: any) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
}

const LoveNote = ({addToRefs}: Props) => {
  const [loveNote, setLoveNote] = useState("");
  const [author, setAuthor] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (author.length === 0) {
      toast.error("Please write your name");
      return;
    }

    if (loveNote.length === 0) {
      toast.error("Please write your Love Note");
      return;
    }

    const target = event.target as HTMLTextAreaElement;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": target.getAttribute("name"),
        "note": loveNote,
        "author": author
      })
    }).then(() => setIsModalOpen(true)).catch(error => alert(error))
  }

  return (
    <Styles.LoveNoteWrapper ref={(ref) => addToRefs({ref, name: "love-note"})}>
      <form data-netlify="true" name="loveNote" method="post" onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="loveNote" />
        <Styles.AuthorInput placeholder="Write your name here..." 
          name="author" 
          value={author} 
          onChange={(e) => setAuthor(e.target.value)} />
        <Styles.LoveNoteTextArea placeholder="Write your love note here..." 
          name="note" 
          value={loveNote} 
          onChange={(e) => setLoveNote(e.target.value)} />
        <Styles.SendNoteBtn type="submit">
          Send Love Note
        </Styles.SendNoteBtn>
      </form>

      <Modal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(!isModalOpen);
          setAuthor("");
          setLoveNote("");
        }}
        headerLabel="Thank you!"
      >
        Your Love Note has been sent.
      </Modal>
    </Styles.LoveNoteWrapper>
  )
}

type Props = {
  addToRefs: (el: any) => void;
}

export default LoveNote;