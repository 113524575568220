import React, { useState } from "react";

import LoveNote from 'src/components/LoveNote';
import HomeHeader from 'src/components/HomeHeader';
import SendGifts from 'src/components/SendGifts';

const IndexPage = () => {
  const [refs, setRefs] = useState<any[]>([]);

  const addToRefs = (element: any) => {
    const hasRef = refs.filter((r) => r.name === element.name);
    if (element.ref !== null && hasRef.length === 0) {
      setRefs(refs.concat(element))
    }
  }

  return (
    <>
      <HomeHeader refs={refs} />
      <LoveNote addToRefs={addToRefs} />
      <SendGifts addToRefs={addToRefs} />
    </>
  )
}

export default IndexPage
