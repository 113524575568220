import styled from 'styled-components';

export const GiftItemWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  margin-bottom: 2.6rem;
  padding: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    min-width: 25vw;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 33vw;

    svg {
      width: 5rem;
    }
  }
`

export const GiftItemButton = styled.button`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  border: none;
`

export const BankDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.ebrima}, Arial, sans-serif;
`

export const ProviderName = styled.span`  
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.ebrima}, Arial, sans-serif;
`