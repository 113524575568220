import styled from "styled-components";

export const ModalHeader = styled.div`
  height: ${(props) => props.theme.spacing.lg};
  background-color: ${(props) => props.theme.colors.paleBrown};
  font-family: ${(props) => props.theme.fonts.caroline}, Arial, sans-serif;
  color: white;  
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem ${(props) => props.theme.spacing.md2};

  .close-icon {
    width: 1rem;
    height: 1rem;
    fill: white;
    cursor: pointer;
  }

  h3 {
    font-size: ${props => props.theme.fontSizes.xl};
    font-weight: normal;
  }
`;

export const ModalContent = styled.div`
  font-family: ${(props) => props.theme.fonts.ebrimaBold}, Arial, sans-serif;
  background-color: ${(props) => props.theme.colors.lightBrown};
  padding: ${(props) => props.theme.spacing.md2};
  color: black;
`;
