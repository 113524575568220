import styled from 'styled-components';

export const HomeHeaderWrapper = styled.div`
  position: relative;
  padding: 0 10rem;
  height: 100vh;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    height: auto;
    padding: 0 0 0 1rem;
  }

  .flower-bg {
    position: absolute;
    left: -5rem;
    bottom: 10vh;
    transform: rotate(35deg);
    z-index: ${props => props.theme.zIndex["10th"]};

    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      top: 0;
    }
  }
`

export const HeaderColumns = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10rem;
  height: 100%;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    padding: 10rem 0 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    justify-content: flex-start;
    align-items: start;
    gap: 1rem;
    padding: 0;
  }

  .logo {
    display: block;
    z-index: ${props => props.theme.zIndex["1st"]};
  }
`

export const ActionLinks = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${props => props.theme.zIndex["1st"]};
`