import React from 'react';

import GiftItem from 'src/components/GiftItem';

import * as Styles from './styled';
import * as DefaultStyles from 'src/components/styled';

const SendGifts = ({addToRefs}: Props) => {
  return (
    <Styles.SendGiftsWrapper ref={(ref) => addToRefs({ref, name: "send-gifts"})}>
        <DefaultStyles.DefaultHeading>
            Send gift
        </DefaultStyles.DefaultHeading>

        <Styles.GiftsRow>
          <GiftItem svgName="bdo" providerName="BDO PESO SAVINGS" accountNum="008010270135"
            accountName="EUNICE N DOMINGO" />
          <GiftItem svgName="bpi" providerName="BPI PESOS SAVINGS ACCOUNT" accountNum="8159252486"
            accountName="EUNICE NOVENO DOMINGO" />
          <GiftItem svgName="gcash" providerName="GCASH" accountNum="09178103007"
            accountName="EUNICE DOMINGO" />
          <GiftItem svgName="bca" providerName="BCA" accountNum="5010411302"
            accountName="MON DAVID BANDOLA MORANTE" />
        </Styles.GiftsRow>
    </Styles.SendGiftsWrapper>
  )
}

type Props = {
  addToRefs: (el: any) => void;
}

export default SendGifts;