import styled from 'styled-components';

export const LoveNoteWrapper = styled.div`
  background-color: ${props => props.theme.colors.lightBrown};
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  position: relative;
  padding: 0 5.6rem;
  align-items: flex-end;
  height: 100vh;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 0 1rem;
  }
`

export const AuthorInput = styled.input`
  background: none;
  border: 1px solid rgba(0,0,0,.16);
  color: ${props => props.theme.colors.paleBrown};
  font-family: ${props => props.theme.fonts.caroline}, Arial, sans-serif;
  font-size: ${props => props.theme.fontSizes.xl};
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  text-indent: 1rem;

  &:focus {
    outline: none;
    border: 1px solid rgba(0,0,0,.16);
  }
`

export const LoveNoteTextArea = styled.textarea`
  flex: 1;
  background: none;
  border: 1px solid rgba(0,0,0,.16);
  color: ${props => props.theme.colors.paleBrown};
  font-family: ${props => props.theme.fonts.caroline}, Arial, sans-serif;
  font-size: ${props => props.theme.fontSizes.xxl};
  height: 100%;
  resize: none;
  padding: 1rem;
  text-indent: 1rem;

  &:focus {
    outline: none;
    border: 1px solid rgba(0,0,0,.16);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2rem;
  }
`

export const SendNoteBtn = styled.button`
  font-size: ${props => props.theme.fontSizes.md};
  font-family: ${props => props.theme.fonts.ebrima}, Arial, sans-serif;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: white;
  border: 1px solid black;
  width: 21rem;
  align-self: flex-end;
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    width: 100%;
  }
`