import styled from 'styled-components';

export const ActionLinkWrapper = styled.div`
  display: flex;
  margin-bottom: 2.6rem;
  padding: 1rem;

  &:hover {
    background-color: ${props => props.theme.colors.lightBrown};
  }

  svg {
    width: 5.18rem;
    height: 5.18rem;
  }

  a {
    text-decoration: none;
    display: flex;
  }  
`

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  margin-left: 2.6rem;

  h5 {    
    font-size: ${props => props.theme.fontSizes.md};
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.ebrimaBold}, Arial, sans-serif;
    margin: 0;

    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      font-size: ${props => props.theme.fontSizes.sm};
    }
  }

  h2 {
    font-size: ${props => props.theme.fontSizes.xl};
    font-family: ${props => props.theme.fonts.ebrima}, Arial, sans-serif;
    font-weight: normal;
    margin: 0;

    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      font-size: ${props => props.theme.fontSizes.md};
    }
  }
`